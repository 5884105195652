import { isProd } from "~/lib/env";

declare global {
  interface Window {
    dataLayer?: unknown[];
    gtag?: unknown;
  }
}

import type {
  FormSystemName,
  FormSystemNameWithSubject,
} from "~/types/form_system_name";

import type { ParentComponent } from "solid-js";
import { createContext, useContext } from "solid-js";
import { createStore } from "solid-js/store";
import { getWindowSize } from "@solid-primitives/resize-observer";
import { localStorageKnowsIfAlreadySubmittedForNid } from "~/lib/form_already_submitted_localstorage";
import { isFormSystemName } from "~/types/form_system_name";
import type { CrmValues } from "~/components/Forms/api";
import type { FormValue } from "~/types/common";

type ClickEventType = MouseEvent & {
  currentTarget: HTMLElement;
  target: Element;
};

type EventOptions = {
  nid?: number;
  crmValues?: CrmValues;
  formValues?: Record<string, FormValue>;
  formNameOverride?: FormSystemName;
};

export const isClickEventType = (
  event: ClickEventType | EventZone,
): event is ClickEventType => {
  return typeof event === "object" && "target" in event;
};

type EventsContextStore = [
  {
    form?: FormSystemNameWithSubject;
    template?: EventTemplate;
    category?: EventCategory;
    label?: string;
    step?: number;
    gaEnabled: boolean;
    gtmEnabled: boolean;
    tempZone?: EventZone;
  },
  {
    setForm: (form: FormSystemName) => void;
    setTemplate: (template: EventTemplate) => void;
    setCategory: (category: EventCategory) => void;
    setLabel: (label: string) => void;
    setStep: (number: number) => void;
    setGaEnabled: (enabled: boolean) => void;
    setGtmEnabled: (enabled: boolean) => void;
    setTempZone: (e: ClickEventType) => void;

    sendClick: (
      click: EventClickName,
      zone: EventZone,
      options?: EventOptions,
    ) => void;
    /**
     * Send a show event to Analytics Provider.
     *
     * @param {FormSystemName} form
     * @param {ClickEventType} | EventZone eventOrZone
     * @param {EventOptions} options
     *   - sendToBackend: Send event to backend
     *   - nid: Node id to check if form has already been submitted
     */
    sendShowEvent: (
      form: FormSystemNameWithSubject,
      eventOrZone: ClickEventType | EventZone,
      options?: EventOptions,
    ) => void;
    sendSubmitEvent: (options?: EventOptions) => void;
    sendStepEvent: (step: number, options?: EventOptions) => void;
  },
];

const EventsContext = createContext<EventsContextStore>([
  {
    form: undefined,
    template: undefined,
    category: undefined,
    label: undefined,
    step: undefined,
    gaEnabled: false,
    gtmEnabled: false,
    tempZone: undefined,
  },
  {
    /* eslint-disable @typescript-eslint/no-unused-vars */
    setForm: (form: FormSystemNameWithSubject) => {},
    setTemplate: (template: EventTemplate) => {},
    setStep: (step: number) => {},
    setCategory: (category: EventCategory) => {},
    setLabel: (label: string) => {},
    setGaEnabled: (gaEnabled: boolean) => {},
    setGtmEnabled: (gtmEnabled: boolean) => {},
    setTempZone: (e: ClickEventType) => {},

    sendClick: (click: EventClickName, zone: EventZone) => {},
    sendShowEvent: (
      form: FormSystemNameWithSubject,
      eventOrZone: ClickEventType | EventZone,
      options?: EventOptions,
    ) => {},
    sendSubmitEvent: (options?: EventOptions) => {},
    sendStepEvent: (step: number, options?: EventOptions) => {},
    /* eslint-enable @typescript-eslint/no-unused-vars */
  },
]);

export const EventsContextProvider: ParentComponent<EventsContextStore[0]> = (
  props,
) => {
  const [state, setState] = createStore<EventsContextStore[0]>({
      // eslint-disable-next-line solid/reactivity
      gaEnabled: props.gaEnabled || false,
      // eslint-disable-next-line solid/reactivity
      gtmEnabled: props.gtmEnabled || false,
    }),
    store: EventsContextStore = [
      state,
      {
        setForm(form: FormSystemName) {
          setState("form", form);
        },
        setTemplate(template: EventTemplate) {
          setState("template", template);
        },
        setStep(number: number) {
          setState("step", number);
        },
        setCategory(category: EventCategory) {
          setState("category", category);
        },
        setLabel(label: string) {
          setState("label", label);
        },
        setTempZone(e: ClickEventType) {
          const zone = getGaZones(e.target as HTMLElement);
          setState("tempZone", zone as EventZone);
        },
        setGaEnabled(enabled: boolean) {
          setState("gaEnabled", enabled);
        },
        setGtmEnabled(enabled: boolean) {
          setState("gtmEnabled", enabled);
        },
        sendClick(click: EventClickName, zone: EventZone) {
          const tpl = state.template ? state.template : "unknown";
          const label = getEventLabel(click, tpl, zone);

          if (!isProd()) {
            const s = "background-color: #023047; color: white";
            console.groupCollapsed(`🎯️ Click:  %c ${label} `, s);
          }

          // Google Analytics
          if (state.gaEnabled && typeof window.gtag === "function") {
            const obj = {
              event_category: "click",
              event_label: label,
            };

            if (!isProd()) {
              console.debug("GTag event click", obj);
            }
            window.gtag("event", "click", obj);
          }

          // Google Tag Manager
          if (typeof window.dataLayer !== "undefined") {
            const obj = {
              "lead.type": "click",
              "form.name": label || "",
              "form.category": "click",
              event: "click_user",
              "element.template": tpl,
              "element.zone": zone || "",
              "element.click": click,
            };

            if (!isProd()) {
              console.debug("Datalayer", obj);
            }
            window.dataLayer.push(obj);
          }

          if (!isProd()) {
            console.groupEnd();
          }
        },
        sendShowEvent(
          form: FormSystemNameWithSubject,
          eventOrZone: ClickEventType | EventZone,
          options: EventOptions = {},
        ) {
          let zone: EventZone | string;
          if (eventOrZone && isClickEventType(eventOrZone)) {
            zone = getGaZones(eventOrZone.target as HTMLElement);
          } else {
            zone = eventOrZone;
          }

          const tpl = state.template ? state.template : "unknown";
          const label = getEventLabel(form, tpl, zone, options.nid);
          const category = getEventCategory(form);
          setState("form", form);
          setState("label", label);
          setState("category", category);

          sendShowDo(state, form, category, zone, options.nid);
        },
        sendSubmitEvent(options: EventOptions = {}) {
          if (!isProd()) {
            const s = "background-color: #e76f51; color: white";
            console.groupCollapsed(`⛱ ️Submit: %c ${state.label} `, s);
          }

          // Google Analytics
          if (state.gaEnabled && typeof window.gtag === "function") {
            const obj = {
              event_category: state.category,
              event_label: state.label,
            };

            if (!isProd()) {
              console.debug("GTag event submit", obj);
            }
            window.gtag("event", "submit", obj);
          }

          // Google Tag Manager
          if (typeof window.dataLayer !== "undefined") {
            const searchParams = new URLSearchParams(document.location.search);

            const obj = {
              "lead.type": getFormFrenchName(
                options.formNameOverride
                  ? options.formNameOverride
                  : state.form!,
              ),
              "form.name": state.label || "",
              "form.category": state.category || "",
              "lead.id": options.crmValues?.submission_id || "",
              "lead.email": options.crmValues?.email || "",
              "lead.projectType": options.crmValues?.project_type || "",
              "produit.name": options.crmValues?.program_name || "",
              "produit.lot": options.crmValues?.lot_number || "",
              first_contact: options.crmValues?.first_contact || "",
              idLot: options.crmValues?.lot_id || "",
              cog_a: searchParams.has("cog_a") ? searchParams.get("cog_a") : "",
              cog_z: searchParams.has("cog_z") ? searchParams.get("cog_z") : "",
              event: "submitForm",
              "form.hidden": state.label && state.label?.includes("-hidden"),
              "form.optin": options.formValues?.optin_cogedim || "",
              iddemande: options.crmValues?.iddemande || "",
            };
            if (!isProd()) {
              console.debug("Datalayer", obj);
            }
            window.dataLayer.push(obj);
          }

          if (!isProd()) {
            console.groupEnd();
          }
        },
        sendStepEvent(step: number) {
          const stepLabel = "step-" + step;
          const label = [
            state.form,
            stepLabel,
            state.template,
            getCurrentDeviceBasedOnViewport(),
          ].join("_");

          if (!isProd()) {
            const s = "background-color: #bde0fe; color: black";
            console.groupCollapsed(`🦶 ️Step: %c ${label} `, s);
          }

          // Google Analytics
          if (state.gaEnabled && typeof window.gtag === "function") {
            const obj = {
              event_category: state.category,
              event_label: label,
            };

            if (!isProd()) {
              console.debug("GTag event submit", obj);
            }
            window.gtag("event", "step", obj);
          }

          // Google Tag Manager
          if (typeof window.dataLayer === "object") {
            const obj = {
              "lead.type": "step",
              "form.name": label,
              "form.category": state.category,
              event: "step",
            };
            if (!isProd()) {
              console.debug("Datalayer", obj);
            }
            window.dataLayer.push(obj);
          }

          if (!isProd()) {
            console.groupEnd();
          }
        },
      },
    ];

  return (
    <EventsContext.Provider value={store}>
      {props.children}
    </EventsContext.Provider>
  );
};

export function useEventsContext() {
  const context = useContext(EventsContext);
  if (context === undefined) {
    throw new Error(
      "useEventsContext must be used within a EventsContext.Provider",
    );
  }
  return context;
}

/**
 * Returns the ga-zone tree for an element.
 *
 * Returns `unknown` if the element is not in a ga-zone
 *
 * @param  {Element} elem Starting element
 * @return {String} Zone identifier
 */
function getGaZones(elem: HTMLElement): string {
  const zones = [];

  // Get the closest match
  for (
    ;
    elem && elem.parentElement && elem !== document.documentElement;
    elem = elem.parentElement
  ) {
    if (elem.matches("[data-ga-zone]")) {
      zones.push(elem.getAttribute("data-ga-zone"));
    }
  }

  if (zones.length === 0) {
    return "unknown";
  }

  return zones.reverse().join("-");
}

type GetEventLabelFn = (
  element: FormSystemNameWithSubject | EventClickName,
  template: string,
  zone: EventZone | string,
  nid?: number,
) => string;

const getEventLabel: GetEventLabelFn = (element, template, zone, nid) => {
  if (template === "search") {
    // TODO: re-implement me
    // if (window.app.searchResultsViewList) {
    //   template = 'search-list';
    // }
    //
    // if (window.app.searchResultsViewMap) {
    //   template = 'search-map';
    // }
    // // @ts-ignore
    // if (window.app.searchResultsViewMixed) {
    //   template = 'search-mix';
    // }
  }

  const device = getCurrentDeviceBasedOnViewport();

  let already = false;
  if (isFormSystemName(element)) {
    already = nid
      ? localStorageKnowsIfAlreadySubmittedForNid(element, nid)
      : false;
  }

  if (already && ["blueprint", "booklet"].includes(element)) {
    element += "-hidden";
  }

  return [element.replace("_", "-"), template, zone, device].join("_");
};

/**
 * Sends the show event.
 *
 * @param state
 * @param form
 * @param category
 * @param zone
 * @param nid
 */
function sendShowDo(
  state: { template?: string; gaEnabled: boolean; gtmEnabled: boolean },
  form: FormSystemNameWithSubject,
  category: EventCategory,
  zone: EventZone | string,
  nid?: number,
): void {
  const template = state.template ? state.template : "unknown";
  const label = getEventLabel(form, template, zone, nid);

  if (!isProd()) {
    const s = "background-color: #ffb703; color: white";
    console.groupCollapsed(`🧘 Show:   %c ${label} `, s);
  }

  if (state.gaEnabled && typeof window.gtag === "function") {
    const obj = {
      event_category: category,
      event_label: label,
    };

    if (!isProd()) {
      console.debug("GA sending", obj);
    }
    window.gtag("event", "show", obj);
  }

  if (typeof window.dataLayer === "object") {
    const obj = {
      "lead.type": getFormFrenchName(form),
      "form.name": label,
      "form.category": category,
      event: "showForm",
      "form.hidden": label.includes("-hidden"),
    };
    if (!isProd()) {
      console.debug("GTM sending", obj);
    }
    window.dataLayer.push(obj);
  }

  if (!isProd()) {
    console.groupEnd();
  }
}

/**
 * Returns label based on the current size of the viewport.
 */
function getCurrentDeviceBasedOnViewport(): "mobile" | "tablet" | "desktop" {
  const size = getWindowSize();

  // TODO: Laurent, peux-tu checker les dimensions pour COG ?
  if (size.width < 768) {
    return "mobile";
  } else if (size.width < 1024) {
    return "tablet";
  }

  return "desktop";
}

function getEventCategory(form: FormSystemNameWithSubject): EventCategory {
  switch (form) {
    case "instant-callback":
    case "callback":
    case "advisor-callback":
      return "callback";
    case "advisor-appointment":
      return "appointment";
    case "leaflet":
    case "blueprint":
      return "download";
    /* TODO should we remove this one?
    case "preview":
      return "preview";*/
    case "qualification":
      return "qualification";

    case "advisor-question":
    case "information":
      return "question";
    case "simulator-ptz":
    case "simulator-pinel":
    case "simulator-lmnp":
      return "simulator";
    case "cnat":
      return "cnat";
    case "event":
      return "event";
      return "auctions";
    case "financial-study":
      return "financial-study";
    case "contact-us":
    case "sponsorship":
    default:
      return "miscellaneous";
  }
}

/**
 * Returns the French name of a form.
 *
 * @param form
 */
function getFormFrenchName(form: FormSystemNameWithSubject) {
  const o = {
    "instant-callback": "rappel_immediat",
    callback: "etre_appele",
    "contact-us": "contact",
    "advisor-callback": "contacter_conseiller_rappel",
    "advisor-appointment": "contacter_conseiller_rdv",
    "advisor-question": "contacter_conseiller_question",
    cnat: "contacter_conseiller_cnat",
    blackfriday: "inscription_encheres",
    project: "projet_cnat",
    information: "information",
    leaflet: "brochure",
    blueprint: "plan",
    preview: "avant_premiere",
    "simulator-ptz": "simulateur-ptz",
    "simulator-pinel": "simulateur-pinel",
    "simulator-pinel-2": "simulateur-pinel",
    "simulator-lmnp": "simulateur-lmnp",
    "simulator-bouvard": "simulateur-bouvard",
    qualification: "qualification",
    sponsorship: "sponsorship",
    event: "evenement",
    "financial-study": "etude-personnalisee",
    "guide-invest": "guide-invest",
    plot: "vendre-terrain",
    landing: "landing",
    parking: "contact-parking",
  } as const;

  return o[form as keyof typeof o];
}

export type EventAction = "click" | "show" | "submit" | "step";

export type EventTemplate =
  | "home"
  | "parkings"
  | "references"
  | "page-seo"
  | "error"
  | "simulator-ptz"
  | "simulator-pinel"
  | "simulator-lmnp"
  | "simulator-bouvard"
  | "search-all"
  | "search-results"
  | "search-mp-access"
  | "cnat"
  | "page"
  | "landing"
  | "dispatch"
  | "program-preview"
  | "program-out-of-stock"
  | "program-std"
  | "article"
  | "magazine"
  | "press-release"
  | "definition"
  | "flux"
  | "journey"
  | "area"
  | `geography-${1 | 2 | 3 | 4}`
  | "press"
  | "plot"
  | "faq"
  | "sitemap"
  | "unknown";

export type EventCategory =
  | "appointment"
  | "auctions"
  | "callback"
  | "click"
  | "cnat"
  | "download"
  | "event"
  | "financial-study"
  | "miscellaneous"
  | "preview"
  | "qualification"
  | "question"
  | "simulator";

export type EventClickName =
  | "anchor-presentation"
  | "anchor-grid"
  | "anchor-3d"
  | "anchor-sales-office"
  | "banner-pitch"
  | "btn-3d"
  | "btn-3d-lot"
  | "btn-article"
  | "btn-articles"
  | "btn-blueprint"
  | "btn-catalog-services"
  | "btn-cityzia"
  | "btn-cogehome3d"
  | "btn-contact"
  | "btn-contact-advisor"
  | "btn-contact-question"
  | "btn-details-close"
  | "btn-details-open"
  | "btn-directions"
  | "btn-faq"
  | "btn-faqs"
  | "btn-faqs-search"
  | "btn-gallery"
  | "btn-landing"
  | "btn-leaflet"
  | "btn-lot-details"
  | "btn-map"
  | "btn-neighborhood-map"
  | "btn-plot"
  | "btn-program-details"
  | "btn-promo-advisor-appointment"
  | "btn-promo-advisor-callback"
  | "btn-promo-advisor-question"
  | "btn-promo-cityzia"
  | "btn-promo-cnat"
  | "btn-promo-cnat-menu"
  | "btn-promo-faq"
  | "btn-promo-financial-study"
  | "btn-promo-guide-invest"
  | "btn-promo-search"
  | "btn-promo-simulator_lmnp"
  | "btn-promo-simulator_pinel-1"
  | "btn-promo-simulator_pinel-plus-1"
  | "btn-promo-simulator_ptz-2"
  | "btn-promo-tools-borrowing-capacity"
  | "btn-promo-tools-monthly-payments"
  | "btn-promo-tools-notary-fees"
  | "btn-promo-tools-simulator-lmnp"
  | "btn-promo-tools-simulator-pinel"
  | "btn-promo-tools-simulator-ptz"
  | "btn-push-program"
  | "btn-search"
  | "btn-videos"
  | "btn-virtual-tour"
  | "btn-virtual-tour-typology"
  | "btn-visio"
  | "card-promo"
  | "cta-cnat-cnat"
  | "cta-cnat-dwell"
  | "cta-cnat-rent"
  | "grid-switch-bare-ownership"
  | "grid-switch-brs"
  | "grid-switch-lmnp"
  | "grid-switch-mastered"
  | "grid-switch-pinel"
  | "grid-switch-vat"
  | "grid-switch-vat-reduced"
  | "invest-button"
  | `link-${string}`
  | "logo"
  | "main-image"
  | "notary-fees"
  | "other-typology"
  | "search-icon"
  | "switch-dwell"
  | "switch-invest"
  | "tab-services-1"
  | "tab-services-2"
  | "tab-services-3"
  | "typology-details"
  | "simulator-pinel"
  | "simulator-lmnp"
  | "notary-fees";

export type EventZone =
  | "3d-block"
  | "ads"
  | "anchors"
  | "areas"
  | "articles"
  | "autopromo"
  | "cnat"
  | `drawer-${"dwell" | "invest"}`
  | "faq"
  | "first-screen"
  | "footer"
  | "form"
  | "guide"
  | "grid-dwell"
  | "headband"
  | "header-sticky"
  | "in-page"
  | "introduction"
  | "landings"
  | "list"
  | "map-card"
  | "menu"
  | "modal"
  | "objectives"
  | "other-programs-list"
  | "other-programs-map"
  | "plot"
  | "program-details"
  | "programs"
  | "push-program"
  | "results"
  | "invest-block"
  | "sales-office"
  | "sales-office-external"
  | "search"
  | "services"
  | "solutions"
  | "topbar";
